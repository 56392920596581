import { ArrowDown } from "@styled-icons/bootstrap/ArrowDown";
import { ArrowUp } from "@styled-icons/bootstrap/ArrowUp";
import { AddCircle } from '@styled-icons/fluentui-system-filled/AddCircle';
import { setHiringManagerOnboardingComplete } from "actions/auth/authSlice";
import { resetCandidates, setFetchJobs } from "actions/hiringManager/jobs/jobsSlice";
import useJobDispatcher from 'containers/HiringManager/Jobs.util';
import { CONFIG } from "containers/Login/ILogin";
import { useLoader } from "context/loaderContext";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { RootState } from "store";
import styled from "styled-components";
import { JobSearchResult } from "types/Jobs";
import { post } from "utilities";
import { isB2BLogin } from "utilities/commonUtils";
import { API_URL_PREFIX, ConfigTypes, DEFAULT_TOKEN } from "utilities/constants";
import { Header, ProfileModal } from '../Components';
import { UserHintsModal } from "../Components/UserHints";
import { theme } from '../constants';
import { CreateJob } from "./CreateJob";
import { JobsFilter } from "./FilterJob";
import { JobCandidates } from "./JobCandidates";
import { CreateJobTitle } from "./Jobs.styled";
import { JobsCard } from "./JobsCard";
import { fetchAllTeamMembers } from "actions/hiringManager/jobs/jobsActions";

const Wrapper = styled.div<{ form: boolean, isMobile: boolean }>`
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    gap: 1rem;
    justify-content: space-around;

    ${({ form }) => form && `
        justify-content: center;
    `};
`;

export const CardWrapper = styled.div<{ createJobCard?: boolean, createCandidateCard?: boolean, borderTop?: string }>`
    position: relative;
    display: flex;
    flex-direction: column;
    background: #fff;
    color: #212529;
    justify-content: space-between;
    align-items: flex-start;
    box-shadow: 0px 0px 20px rgba(22, 52, 134, 0.15);
    border-radius: 10px;
    flex: 0 0 28%;
    padding: 30px 15px 30px 45px;
    font-size: 18px;
    margin-right: 30px;
    margin-bottom: 30px;
    word-break: break-word;
    
    ${({ borderTop }) => borderTop && `
        border-top: 6px solid ${borderTop};
    `};

    ${({ createJobCard }) => createJobCard && `
        padding: 40px;
        justify-content: center;
        align-items: center;
        svg {
            &:hover {
                opacity: 0.8;
                filter: drop-shadow(0 0 40px #969696);
            }
        }
        
    `};
`;

export const CardSubwrapper = styled.div<{ disable?: boolean }>`
    width: 100%;

    .info-icon-container {
        position: relative;
    }

    .info-icon {
        min-width: 15px;
        max-width: 15px;
        color: #315cd5;

        margin-left: 5px;
        top: 1px;
    }

    ${({ disable }) => disable && `
    `}
`;
// pointer-events: none;
// opacity: .5;

export const JobsContainer = ({ publicJobsFilter }: {
    publicJobsFilter: boolean;
}) => {
    const dispatch = useDispatch();
    const { user, showHiringManagerOnboardingProcess, expertProfile, teamMembers } = useSelector((state: RootState) => state.auth);
    const { isHiringManagerProfileUpdated } = useSelector((state: RootState) => state.jobs);
    const [showCreateJobForm, setShowCreateJobForm] = useState<boolean>(false);
    const [showOnBoardingProcess, setShowOnBoardingProcess] = useState<boolean>(false);
    const [showOnboardingSkip, setShowOnboardingSkip] = useState<boolean>(false);
    const [jobId, setJobId] = useState<string | null>("");
    const { expertId, roleType } = user;
    // const [showJobCandidates, setShowJobCandidates] = useState<boolean>(false);
    const b2bLogin = isB2BLogin(roleType as any);
    const location = useLocation();

    useEffect(() => {
        if (expertId && expertProfile && teamMembers.length === 0) {
            dispatch(fetchAllTeamMembers(expertId));
        }
    }, [expertId, expertProfile])

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const urlJobId = queryParams.get('jobId');

        if (jobId !== urlJobId) {
            setJobId(urlJobId);
        }
    }, [location])

    const handleCreateJob = () => {
        setShowCreateJobForm(true);
    };

    const submitOnBoardingAsDone = async () => {
        setShowOnBoardingProcess(false);
        const onBoardingConfig = (user.configs || []).find((o: CONFIG) => o.key === ConfigTypes.ON_BOARDING_PROCESS);
        const value = !onBoardingConfig ? 1 : (onBoardingConfig.value as number) + 1;
        dispatch(setHiringManagerOnboardingComplete());
        await post(API_URL_PREFIX + '/addExpertConfig', { token: DEFAULT_TOKEN, expertId: expertId, config: { key: ConfigTypes.ON_BOARDING_PROCESS, value } });
    };

    useEffect(() => {
        if (showHiringManagerOnboardingProcess) {
            setShowOnBoardingProcess(true);
            const onBoardingConfig = (user.configs || []).find((o: CONFIG) => o.key === ConfigTypes.ON_BOARDING_PROCESS);
            onBoardingConfig && setShowOnboardingSkip(true);
        }
    }, [showHiringManagerOnboardingProcess]);

    return (
        <>
            {!jobId}
            {!jobId && <JobsFilter publicJobsFilter={publicJobsFilter} />}
            <Header title="Active Jobs" />
            <Wrapper form={showCreateJobForm} isMobile={isMobile}>
                <AllJobs
                    handleCreateJob={handleCreateJob}
                    showJobCandidates={!!jobId}
                    publicJobsFilter={publicJobsFilter}
                    jobId={jobId!} />
                {showCreateJobForm && (
                    <CreateJob setShowCreateJobForm={setShowCreateJobForm} />
                )}
                <UserHintsModal handleSubmit={submitOnBoardingAsDone} show={showOnBoardingProcess} canSkip={showOnboardingSkip} />
                <ProfileModal show={!isHiringManagerProfileUpdated && b2bLogin} />
            </Wrapper>
        </>
    );
};

export const AllJobs = ({ handleCreateJob, showJobCandidates, publicJobsFilter, jobId }: {
    handleCreateJob: () => void;
    showJobCandidates: boolean;
    publicJobsFilter?: boolean;
    jobId: string;
}) => {
    const Loader = useLoader();
    const dispatch = useDispatch();
    const { getJobs, getJobCandidates } = useJobDispatcher();
    const state = useSelector((state: RootState) => state);
    const { expertId } = state.auth.user;
    const { loading, fetchJobs, jobResults, inActivejobs } = state.jobs;
    const [selectedJob, setSelectedJob] = useState<JobSearchResult>();
    const [showInactiveJobs, setShowInactiveJobs] = useState<boolean>(false);
    const notificationJobId = sessionStorage.getItem('notificationJobId');
    const debounceLocationData = useCallback(debounce(() => {
        getJobs({ publicJobs: publicJobsFilter, expertId });
        dispatch(setFetchJobs({ fetchJobs: false }));
    }, 500), [expertId, fetchJobs, publicJobsFilter]);

    loading ? Loader.showLoader() : Loader.hideLoader();

    useEffect(() => {
        if (expertId && fetchJobs) {
            debounceLocationData();
        }
    }, [expertId, fetchJobs]);

    useEffect(() => {
        if (notificationJobId) {
            handleShowJobCandidates(notificationJobId);
            sessionStorage.removeItem('notificationJobId');
        }
        !fetchJobs && dispatch(setFetchJobs({ fetchJobs: true }));
    }, []);

    useEffect(() => {
        if (jobId && expertId && jobResults) {
            handleShowJobCandidates(jobId);
        }
    }, [jobId, expertId, jobResults])

    const handleShowJobCandidates = (jobId: string) => {
        const job = jobResults?.find(job => job.jobId === jobId)!;
        dispatch(resetCandidates());
        setSelectedJob(job);
        getJobCandidates({ jobId: job?.jobId });
    };

    const toggleJobSelection = () => {
        setShowInactiveJobs(prev => !prev)
    }

    if (showJobCandidates && selectedJob) {
        return <JobCandidates job={selectedJob!} publicJobsFilter={publicJobsFilter} />;
    }

    return (
        <>
            {!publicJobsFilter && <CreateJobCard handleCreateJob={handleCreateJob} />}
            {jobResults?.slice().reverse().map((job) => (
                <JobsCard
                    key={job.jobId}
                    handleCheckCandidates={handleShowJobCandidates}
                    {...job}
                    publicJobsFilter={publicJobsFilter} />
            ))}
            <div className={`d-flex align-items-center w-100 my-5`}>
                <Header title="Inactive Jobs" />
                {!showInactiveJobs && <ArrowDown width={'2rem'} className="ml-2 cursor-pointer" color="blue" onClick={toggleJobSelection} />}
                {showInactiveJobs && <ArrowUp width={'2rem'} className="ml-2 cursor-pointer" color="blue" onClick={toggleJobSelection} />}
            </div>
            {showInactiveJobs && inActivejobs?.slice().reverse().map((job) => (
                <JobsCard
                    key={job.jobId}
                    handleCheckCandidates={handleShowJobCandidates}
                    {...job}
                    publicJobsFilter={publicJobsFilter} />
            ))}
        </>
    );
}

const CreateJobCard = ({ handleCreateJob }: { handleCreateJob: () => void }) => {
    return (
        <CardWrapper className="job-card" createJobCard>
            <AddCircle cursor='pointer' onClick={handleCreateJob} color={theme.colors.PRIMARY_01} height='4rem' />
            <CreateJobTitle>Create Job</CreateJobTitle>
        </CardWrapper>
    );
};

