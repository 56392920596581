import React, { useState } from "react";
import { Modal } from 'react-bootstrap'
import { Field } from 'formik';
import { Header, Wrapper, Body, Footer, CancelButton, SubmitButton, CandidateName, ReasonWrapper } from "./ConfirmationModal.styled";
import { messages } from "pages/B2B/constants";

export type ConfirmationProps = {
    show: boolean;
    buttonText?: string;
    candidateName?: string;
    showReason?: boolean;
    onSuccess?: (reason: string) => void;
    onReject?: () => void;
}

const ConfirmationModal = ({ show, buttonText, candidateName, showReason, onSuccess, onReject }: ConfirmationProps) => {
    const [reason, setReason] = useState('');
    const [showError, setShowError] = useState(false);

    const reasonLabel = buttonText === messages.FINALISE ? 'Finalize' : 'Rejection';

    const onReasonChange = (e: any) => {
        setShowError(false);
        setReason(e.target.value);
    };

    const handleSuccess = () => {
        if (showReason && !reason.trim()) {
            setShowError(true);
        }else {
            onSuccess?.(reason ?? reason);
        }
    };

    return (
        <Modal
            show={show}
            onHide={() => onReject?.()}
            centered
        >
            <Wrapper>
                <Modal.Header className="border-0" closeButton={true}>
                    <Header>Confirmation</Header>
                </Modal.Header>
                <Modal.Body>
                    <Body>
                        <div>Are you sure you want to {buttonText}?</div>
                        <CandidateName>
                            <span>Candidate Name</span>:&nbsp;
                            <span>{candidateName}</span>
                        </CandidateName>
                    </Body>
                    {showReason && (
                        <ReasonWrapper>
                            <div className="d-flex">
                                <div className="label">Reason</div>
                                <span style={{ color: 'red' }}>&nbsp;*</span>
                            </div>
                            <div className="d-flex flex-column">
                                <textarea
                                    className="form-control"
                                    rows={3}
                                    placeholder={`Reason for ${reasonLabel}`}
                                    value={reason}
                                    onChange={onReasonChange}
                                />
                                {showError && <div className="invalid-feedback">Reason is required</div>}
                            </div>
                        </ReasonWrapper>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Footer>
                        <CancelButton onClick={() => onReject?.()}>
                            Cancel
                        </CancelButton>
                        <SubmitButton onClick={handleSuccess}>
                            Yes, Proceed
                        </SubmitButton>
                    </Footer>
                </Modal.Footer>
            </Wrapper>
        </Modal>
    )
};

export default ConfirmationModal;