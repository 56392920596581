import { Backdrop, IconButton } from "@mui/material";
import { InfoCircle } from "@styled-icons/boxicons-solid/InfoCircle";
import { Add } from '@styled-icons/material-rounded/Add';
import { resetSelectedSubCategoriesScores, resetTrackTree, setSelectedSubCategoriesScores } from "actions/hiringManager/jobs/jobsSlice";
import useJobDispatcher, { JOB_ACCESS_LIST } from 'containers/HiringManager/Jobs.util';
import { Category } from 'containers/Meeting/meetingTypes';
import { Field, Form, Formik } from 'formik';
import { groupBy } from "lodash";
import { validateText, validateURL } from 'pages/B2B/Components/Profile/Profile.util';
import { CUSTOM_TRACK_VALUE } from "pages/B2B/constants";
import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from "react-bootstrap";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from "react-tooltip";
import { RootState } from 'store';
import { KeyboardBackspace } from "styled-icons/material";
import { Entity } from 'utilities/constants';
import * as Yup from 'yup';
import JobAttributes from "../JobAttributes/JobAttributes";
import { ScoreList } from "../JobAttributes/SubCategoriesViewer";
import { FieldSet, Label } from '../Jobs.styled';
import { JobActionButton, SelectComponent } from "./CreateJob";
import { RoundedDiv, SelectedSubCatContainer, SelectedSubCategoryViewer } from "./styled";

const ValidationSchema = Yup.object({
    track: Yup.string()
        .required("Job Role is required"),
    openPositions: Yup.string()
        .required("Position is required"),
    jobAccess: Yup.string()
        .required("Job Access is required"),
    equity: Yup.string(),
});

export type CategoryComponent = {
    key: string,
    id: string,
    title: string,
    onSelectValueOptionChange: (value: number) => void,
    children: SubCategoryComponent[],
}

export type SubCategoryComponent = {
    key: string,
    id: string,
    title: string,
    onSelectValueOptionChange: (value: number) => void;
}

const JobDetailsForm = (props: {
    initialValues: any,
    handleNext: (values: any) => void,
    handleBack?: (values: any) => void,
    updatedScores: React.MutableRefObject<any>,
    isEditMode?: boolean,
    selectedTrackId: string,
    setSelectedTrackId: React.Dispatch<React.SetStateAction<string>>,
    expandedCategories: Set<string | void>,
    setExpandedCategories: React.Dispatch<React.SetStateAction<Set<string | void>>>,
    attributeDrawerParentElement: Element | null
}) => {

    const position = Array.from({ length: 20 }, (v, k) => ({ key: k + 1, value: k + 1 }));
    const dispatch = useDispatch();
    const { trackTree, jobTracks, selectedSubCategoriesScores } = useSelector((state: RootState) => state.jobs);
    const { getTrackTree } = useJobDispatcher();
    const [treeData, setTreeData] = useState<CategoryComponent[]>([]);
    const [checkedKeys, setCheckedKeys] = useState<string[]>(props.initialValues.checkedKeys ?? []);
    const [elementToBringInViewPort, setElementToBringInViewPort] = useState<Element | null>(null);
    const {
        selectedTrackId,
        setSelectedTrackId,
        expandedCategories,
        setExpandedCategories,
    } = props;
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const updatedScores = props.updatedScores;
    
    useEffect(() => {
        if(trackTree.length > 0 && checkedKeys.length === 0 && !props.isEditMode) {
            setDrawerOpen(true);
        }
    }, [trackTree, checkedKeys])

    useEffect(() => {
        if (trackTree.length) {
            setTree(trackTree);
        }
    }, [selectedTrackId, trackTree]);

    useEffect(() => {
        if (props.initialValues.checkedKeys) {
            setCheckedKeys(props.initialValues.checkedKeys);
        }
    }, [props.initialValues.checkedKeys])

    const onSubCatScoreChange = (value: number, subcatkey: string, catkey: string) => {
        onScoreOptionChange(value, [subcatkey], false);
        const newScores = getAvgScoreForCategory(catkey);
        updateSelectedScores(newScores);
    }

    const onCatScoreChange = (value: number, categories: any[], catkey: string) => {
        const ids = categories.map((subcat: any) => subcat?.key)
            .filter((key: string) => {
                return (key in updatedScores.current)
            });
        ids.push(catkey);
        onScoreOptionChange(value, ids, false);
    }

    const setTree = (categories: Category[]) => {
        const categoryTree = categories.map((cat: any) => {
            const catkey = cat.categoryId + '-' + Entity.CATEGORY;
            const categories = cat.subCategories?.map((subcat: any) => {
                const subcatkey =
                    cat.categoryId + '-' + Entity.CATEGORY +
                    '-' +
                    subcat?.subCategoryId +
                    '-' +
                    Entity.SUBCATEGORY;
                return {
                    key: subcatkey,
                    id: subcat?.subCategoryId,
                    title: subcat.subCategoryName,
                    onSelectValueOptionChange: (value: number) => onSubCatScoreChange(value, subcatkey, catkey)
                };
            });
            return {
                key: catkey,
                id: cat.categoryId,
                title: cat.categoryName,
                onSelectValueOptionChange: (value: number) => onCatScoreChange(value, categories, catkey),
                children: categories,
            };
        });
        setExpandedCategories(prev => new Set(prev).add(selectedTrackId));
        setTreeData(categoryTree ?? []);
    };

    const handleTrackChange = (trackId: string) => {
        trackId && trackId !== CUSTOM_TRACK_VALUE && getTrackTree(trackId);
        setSelectedTrackId(trackId);

        // dispatch(resetSelectedSubCategoriesScores());
        setCheckedKeys([]);
        updatedScores.current = {};
        setExpandedCategories(new Set());
    };

    const onCheck = (checkedKeys: string[], checkedNodeKey: string) => {
        setCheckedKeys(checkedKeys);
        onCheckCategorySyncScores(checkedKeys);
        const [categoryKey, category] = checkedNodeKey.split('-');
        const catKey = categoryKey + "-" + category;
        const newScores = getAvgScoreForCategory(categoryKey + "-" + category, expandedCategories.has(catKey));
        updateSelectedScores(newScores);
    };

    const handleExpandedCatagories = (expandedCategories: Set<string | void>) => {
        setExpandedCategories(expandedCategories);
        onExpandHandleScores(expandedCategories);
        setCheckedKeys(keys => {
            return [...keys]
        });
    };

    const onCheckCategorySyncScores = (checkedKeys: string[]) => {
        const newScores: any = { ...updatedScores.current };
        const oldScores = updatedScores.current;
        const checkedKeysSet = new Set(checkedKeys);
        const checkedKeysParantSet = new Set(checkedKeys.map(key => {
            const [categoryId, categoryType] = key.split('-');
            return categoryId + "-" + categoryType;
        }));
        for (let i of checkedKeys) {
            if (oldScores[i]) {
                const value = oldScores[i].value === null ? 7 : oldScores[i].value;
                newScores[i] = { ...oldScores[i], value };
            } else {
                newScores[i] = { value: 7, 'disabled': false };
            }
        }
        for (let key in oldScores) {
            const [categoryId, categoryType, subCategoryId] = key.split('-');
            if ((subCategoryId && !checkedKeysSet.has(key)) ||
                (!subCategoryId && !checkedKeysParantSet.has(categoryId + "-" + categoryType))) {
                delete newScores[key];
            }
        }
        updateSelectedScores(newScores);
    };

    const getAverageScore = (subCatKeys: string[], catkey: string) => {
        const oldScores = updatedScores.current;
        let sum = 0, count = 0;
        for (let subCatId of subCatKeys) {
            if (subCatId in oldScores) {
                sum += oldScores[subCatId].value;
                count++;
            }
        }
        if (count === 0) return 0;
        return Math.round(sum / count);
    };

    const getAvgScoreForCategory = (catkey: string, disabledScore?: boolean) => {
        const subCatKeys = trackTree.find((cat) => {
            const key = cat.categoryId + '-' + Entity.CATEGORY;
            return key === catkey
        })?.subCategories?.map((subcat: any) => {
            const subcatkey =
                catkey + '-' +
                subcat?.subCategoryId +
                '-' +
                Entity.SUBCATEGORY;
            return subcatkey;
        })
        const newScores: any = { ...updatedScores.current };
        newScores[catkey] = {
            value: getAverageScore(subCatKeys!, catkey),
            'disabled': !!disabledScore || newScores[catkey]?.['disabled']
        };
        return newScores;
    };

    const updateSelectedScores = (newScores: any) => {
        updatedScores.current = newScores;
        dispatch(setSelectedSubCategoriesScores(newScores));
    };

    const onScoreOptionChange = (value: number, ids: string[], disableOnInsert: boolean) => {
        const newScores: any = { ...updatedScores.current };
        for (let id of ids) {
            newScores[id] = { value, 'disabled': disableOnInsert };
        }
        updateSelectedScores(newScores);
    };

    const onExpandHandleScores = (expandedCategories: Set<string | void>) => {
        const newScores: any = { ...updatedScores.current };
        const oldScores = updatedScores.current;
        const expandedKeysSet = new Set(expandedCategories);
        const expandedCategoriesArr = Array.from(expandedCategories);
        for (let expandedKey of expandedCategoriesArr) {
            if (!expandedKey) {
                continue;
            }
            if (oldScores[expandedKey]) {
                newScores[expandedKey] = { ...oldScores[expandedKey], 'disabled': true };
            } else {
                newScores[expandedKey] = { value: null, 'disabled': true };
            }
        }
        for (let key in oldScores) {
            if (!expandedKeysSet.has(key)) {
                newScores[key] = { ...oldScores[key], 'disabled': false };
            }
        }
        updateSelectedScores(newScores);
    };
    // -----------------------

    const disableSubmit = (formik: any) => {
        return !formik.isValid || checkedKeys.length < 1;
    };

    const getDataToSave = (values: any) => {
        return { ...values, checkedKeys };
    }

    const openClickedSubCat = (catKey: string, subCatKey: string) => {
        setExpandedCategories(prev => prev.has(catKey) ? prev : new Set(prev).add(catKey));
        setDrawerOpen(true);
        setElementToBringInViewPort(document.getElementById(subCatKey));
    }

    const selectedKeyDisplayComponent = useMemo(() => {
        const groupedCheckedKeys = groupBy(checkedKeys, (key) => {
            const [categoryId, categoryType] = key.split('-');
            return categoryId + "-" + categoryType
        });
        const displayData = [];
        for (let categoryKey in (groupedCheckedKeys ?? {})) {
            const [categoryId] = categoryKey.split('-');
            const category = treeData.find(data => data.key.includes(categoryId));
            for (let subCatKey of groupedCheckedKeys[categoryKey]) {
                const [categoryId, categoryType, subCategoryId, subCategoryType] = subCatKey.split('-');
                const subCategory = category?.children?.find(data => data.key.includes(subCategoryId));
                if (category && subCategory) {
                    displayData.push(
                        <>
                            <SelectedSubCategoryViewer
                                key={subCatKey + "_data_view"}
                                className="p-2"
                                onClick={() => openClickedSubCat(category.key, subCategory.key)}
                            >
                                <div>
                                    <span className="category-title">{category.title}</span>
                                    <span>{subCategory.title}</span>
                                </div>
                                <div>
                                    <select
                                        className='selectBox'
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                        onChange={(e) => {
                                            onSubCatScoreChange(+e.target.value, subCategory.key, category.key);
                                        }}
                                        value={selectedSubCategoriesScores[subCatKey].value}
                                    >
                                        {ScoreList.map((val, idx) => <option key={idx} value={val}>{val}</option>)}
                                    </select>
                                </div>
                            </SelectedSubCategoryViewer>
                        </>
                    )
                }
            }
        }
        return displayData;
    }, [checkedKeys, treeData, selectedSubCategoriesScores])

    const selectAllSubCats = () => {
        const newScores = { ...updatedScores.current };
        let allSubCatKeys: string[] = [];
        for (let cat of trackTree) {
            const subCatKeys = [];
            const catkey = cat.categoryId + '-' + Entity.CATEGORY;
            for (let subCat of (cat.subCategories ?? [])) {
                const subCatKey =
                    catkey + '-' +
                    subCat?.subCategoryId +
                    '-' +
                    Entity.SUBCATEGORY;
                subCatKeys.push(subCatKey);
                if (!newScores[subCatKey]) {
                    newScores[subCatKey] = {
                        value: 7,
                        'disabled': false
                    }
                }
            }
            newScores[catkey] = {
                value: getAverageScore(subCatKeys, catkey),
                "disabled": false
            }
            allSubCatKeys = [...allSubCatKeys, ...subCatKeys];
        }
        setCheckedKeys(allSubCatKeys);
        updateSelectedScores(newScores);
    }

    const resetAllSelection = () => {
        setCheckedKeys([]);
        updateSelectedScores({});
    }

    return (
        <Formik
            initialValues={{ ...props.initialValues }}
            onSubmit={() => { }}
            validationSchema={ValidationSchema}
            enableReinitialize
        >
            {(formik) => (
                <Form style={{ position: "relative" }}>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={drawerOpen}
                        onClick={() => setDrawerOpen(false)}
                    />
                    <Row>
                        <Col>
                            <FieldSet className="mt-4 mb-4">
                                <Label className="font-weight-bold">
                                    Select Job Role
                                    <span className='required'>&nbsp;*</span>
                                </Label>
                                {props.isEditMode ?
                                    <Field disabled placeholder='Job Track' name='track' title='Job Track' type='text' />
                                    :
                                    <Row className="align-items-center">
                                        <div className="col-10">
                                            <Field
                                                name='track'
                                                keyParam='trackId'
                                                valueParam='title'
                                                title='Select Job Role'
                                                addNameToValue
                                                options={jobTracks}
                                                type='text'
                                                onChange={handleTrackChange}
                                                component={SelectComponent}
                                            />
                                        </div>
                                        <div className="col">
                                            <IconButton size="small" style={{ backgroundColor: `#27ae60` }}
                                                data-tip={'Create Custom Track'}
                                                data-for={'create-custom-track-btn'}
                                                onClick={() => handleTrackChange(CUSTOM_TRACK_VALUE)}
                                            >
                                                <Add
                                                    style={{ width: '18px', color: '#fff' }}
                                                />
                                            </IconButton>
                                            <ReactTooltip id={'create-custom-track-btn'} type="dark" place='bottom' effect='solid' />
                                        </div>
                                    </Row>
                                }
                            </FieldSet>
                        </Col>
                        <Col>
                            <FieldSet className="mt-4 mb-4">
                                <Label className="font-weight-bold">
                                    Job Title
                                    <span className='required'>&nbsp;*</span>
                                </Label>
                                <Field placeholder='Job Title' className="form-control" name='jobTitle' title='Job Title' type='text' validate={(value: string) => validateText(value, 'Job title')} />
                            </FieldSet>
                        </Col>
                        <Col>
                            <FieldSet className="mt-4 mb-4">
                                <Label className="font-weight-bold">
                                    Job Access
                                    <span className='required'>&nbsp;*</span>
                                    <InfoCircle
                                        data-tip="Public - Viewable by all recruiters. Private - Only creator can access"
                                        data-for="job-access-description"
                                    />
                                    <ReactTooltip id="job-access-description" type="dark" />
                                </Label>
                                <Field
                                    name='jobAccess'
                                    keyParam='value'
                                    valueParam='text'
                                    addNameToValue={false}
                                    options={JOB_ACCESS_LIST}
                                    type='text'
                                    component={SelectComponent}
                                />
                                {(formik as any).errors['jobAccess'] && (formik as any).touched['jobAccess'] && (
                                    <div className="invalid-feedback">{(formik as any).errors['jobAccess']}</div>
                                )}
                            </FieldSet>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FieldSet className="mt-4 mb-4">
                                <Label className="font-weight-bold">
                                    Total Open Positions
                                    <span className='required'>&nbsp;*</span>
                                </Label>
                                <Field name='openPositions' keyParam='key' valueParam='value' title='Select No of Open Positions' options={position} type='text' component={SelectComponent} />
                            </FieldSet>
                        </Col>
                        <Col>
                            <FieldSet className="mt-4 mb-4">
                                <Label className="font-weight-bold">
                                    Job Description Link
                                    <span className='required'>&nbsp;*</span>
                                </Label>
                                <Field placeholder='Job Description Link' className="form-control" name='jobLink' title='Job Description Link' type='text' validate={(value: string) => validateURL(value, 'Job description link')} />
                                {(formik as any).errors['jobLink'] && (formik as any).touched['jobLink'] && (
                                    <div className="invalid-feedback">{(formik as any).errors['jobLink']}</div>
                                )}
                            </FieldSet>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <button
                                onClick={() => setDrawerOpen(true)}
                                type="button"
                                className="btn btn-success"
                            >
                                <span className="mr-2">Define your hiring bar</span>
                                <RoundedDiv>
                                    {checkedKeys.length}
                                </RoundedDiv>
                            </button>
                            {props.attributeDrawerParentElement && createPortal(
                                <JobAttributes
                                    anchor="left"
                                    open={drawerOpen}
                                    expandedCategories={expandedCategories}
                                    checkedKeys={checkedKeys}
                                    onCheck={onCheck}
                                    handleExpandedCatagories={handleExpandedCatagories}
                                    data={treeData}
                                    elementToBringInViewPort={elementToBringInViewPort}
                                    setElementToBringInViewPort={setElementToBringInViewPort}
                                    selectAllSubCats={selectAllSubCats}
                                    resetAllSelection={resetAllSelection}
                                />,
                                props.attributeDrawerParentElement
                            )}
                        </Col>
                    </Row>
                    <SelectedSubCatContainer className="mt-3">
                        {selectedKeyDisplayComponent}
                    </SelectedSubCatContainer>
                    <Row className="justify-content-end">
                        {props.handleBack && <JobActionButton
                            onClick={() => {
                                props.handleBack && props.handleBack(getDataToSave(formik.values));
                            }}
                            type="submit"
                            variant="secondary"
                            disabled={disableSubmit(formik)}
                        >
                            <KeyboardBackspace width={'20px'} />
                            {'Back'}
                        </JobActionButton>}
                        <JobActionButton
                            onClick={() => {
                                props.handleNext(getDataToSave(formik.values));
                            }}
                            type="submit"
                            variant="primary"
                            disabled={disableSubmit(formik)}
                        >
                            {'Save & Next'}
                        </JobActionButton>
                    </Row>
                </Form>
            )}
        </Formik>
    )
}

export default JobDetailsForm;