import { Autocomplete, Box, Button, IconButton, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { SkipBackwardFill } from '@styled-icons/bootstrap/SkipBackwardFill';
import { SkipForwardFill } from '@styled-icons/bootstrap/SkipForwardFill';
import { Shuffle } from '@styled-icons/entypo/Shuffle';
import { PersonSwap } from '@styled-icons/fluentui-system-filled/PersonSwap';
import { SlideArrowRight } from '@styled-icons/fluentui-system-filled/SlideArrowRight';
import { CaretBack } from '@styled-icons/ionicons-outline/CaretBack';
import { CaretForward } from '@styled-icons/ionicons-outline/CaretForward';
import OverflowTip from "components/Common/OverflowTip/OverflowTip";
import ScrollableMention from "components/Mentions/Mention";
import { TeamMemberDataType } from "containers/Settings/CreateTeam/Types/types";
import React, { useRef, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Delete } from 'styled-icons/material';
import { TASK_STATUS, Task } from "types/Jobs";
import { getDateTimeInLocalFormat } from "utilities/commonUtils";
import { openUrlInNewTab } from "utilities/utils";
import { StatusToDisplayText, TaskStatusToIcon, TaskStatusToKeyArray } from "./TaskDisplayer";

type IProps = {
    tasks: Task[];
    rowsPerPage: number;
    totalTasks: number;
    setPage: (page: number) => void;
    setRowsPerPage: (page: number) => void;
    currPage: number;
    handleTaskDelete: (taskId: string) => void;
    changeTaskStatus: (taskId: string, jobId: string, newStatus: TASK_STATUS) => void;
    changeTaskAssignee: (taskId: string, jobId: string, updateExpertIdToAssignTask: string) => void;
    teamMembers: TeamMemberDataType[];
    showJobCandidate: (taskId: string) => void;
}

const headerRowStyle = {}
const actionButtonStyle = {
    width: '1.5rem',
    cursor: 'pointer',
    marginRight: '0.5rem'
}

const ActionToolTipId = 'action-tool-tip'

const TaskTable = (props: IProps) => {

    const [confirmBoxEl, setConfirmBoxEl] = useState<any | null>(null);
    const [changeTaskStatusEl, setChangeTaskStatusEl] = useState<any | null>(null);
    const [taskAssigneeChangeEl, setTaskAssigneeChangeEl] = useState<any | null>(null);
    const [actionTask, setActionTask] = useState<{
        status: TASK_STATUS | null,
        taskId: string | null,
        jobId: string | null,
        taskedAssignedExpert: string | null

    }>({ jobId: null, status: null, taskId: null, taskedAssignedExpert: null })

    const actionSuccessCb = useRef<Function | null>();

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        props.setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        props.setRowsPerPage(parseInt(event.target.value, 10));
        props.setPage(0);
    };

    const deleteTask = (el: any, taskId: string) => {
        setConfirmBoxEl(el);
        actionSuccessCb.current = () => props.handleTaskDelete(taskId)
    }

    const onConfirmAction = () => {
        if (actionSuccessCb.current) {
            actionSuccessCb.current();
            actionSuccessCb.current = null;
        }
    }

    const onCancelAction = () => {
        actionSuccessCb.current = null;
    }

    const openJobPage = (jobId: string) => {
        const url = window.location.origin + '/jobs' + '?jobId=' + jobId;
        openUrlInNewTab(url);
    }

    const showJobCandidate = (taskId: string) => {
        props.showJobCandidate(taskId);
    }

    const handleTaskStatusChange = (taskId: string, jobId: string, newStatus: TASK_STATUS) => {
        props.changeTaskStatus(taskId, jobId, newStatus);
    }

    const handleTaskAssigneeChange = (taskId: string, jobId: string, newAssigneeId: string) => {
        props.changeTaskAssignee(taskId, jobId, newAssigneeId);
    }

    const setActionDataFromTask = (task: Task) => {
        setActionTask({
            status: task.status, jobId: task.jobId, taskId: task.taskId, taskedAssignedExpert: task.expertId
        })
    }

    const resetActionData = () => {
        setActionTask({
            status: null, jobId: null, taskId: null, taskedAssignedExpert: null
        });
    }

    return (
        <>
            <TableContainer component={Paper} sx={{ maxHeight: "75vh" }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={headerRowStyle}>Task</TableCell>
                            <TableCell sx={headerRowStyle}>Status</TableCell>
                            <TableCell sx={headerRowStyle}>Info</TableCell>
                            <TableCell sx={headerRowStyle}>Posted At</TableCell>
                            <TableCell sx={headerRowStyle}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.tasks.map((task, idx) => (
                            <TableRow
                                key={task.noteId + idx}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell sx={{ maxWidth: "400px" }}>
                                    <ScrollableMention
                                        onChange={(txt, plainTxt, mentions) => { }}
                                        placeholder=''
                                        trigger=''
                                        userMentionData={[]}
                                        value={task.message}
                                        disable={true}
                                        maxHeight={50}
                                        singleLine
                                    />
                                </TableCell>
                                <TableCell sx={{ minWidth: "180px" }}>
                                    <TaskStatusToIcon status={task.status} label={StatusToDisplayText[task.status]} />
                                </TableCell>
                                <TableCell sx={{ minWidth: "230px" }}>
                                    <div className='d-flex mt-1'>
                                        <span style={{ color: '#6F6F6F', minWidth: '3.2rem' }}>Candidate:&nbsp;</span>
                                        <div style={{ width: '200px' }}>
                                            <OverflowTip title={task.candidateName}>
                                                <span className='font-weight-bold'>{task.candidateName}</span>
                                            </OverflowTip>
                                        </div>
                                    </div>
                                    <div className='d-flex mt-1'>
                                        <span style={{ color: '#6F6F6F', minWidth: '3.2rem' }}>Job Title:&nbsp;</span>
                                        <div style={{ width: '200px' }}>
                                            <OverflowTip title={task.jobTitle}>
                                                <span className='font-weight-bold'>{task.jobTitle}</span>
                                            </OverflowTip>
                                        </div>
                                    </div>
                                    <div className='d-flex mt-1'>
                                        <span style={{ color: '#6F6F6F', minWidth: '3.2rem' }}>Assignee:&nbsp;</span>
                                        <div style={{ width: '200px' }}>
                                            <OverflowTip title={task.expertName}>
                                                <span className='font-weight-bold'>{task.expertName}</span>
                                            </OverflowTip>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell sx={{ minWidth: "180px" }}>
                                    {getDateTimeInLocalFormat(task.createdAt)}
                                </TableCell>
                                <TableCell sx={{ minWidth: "160px" }}>
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                            <Tooltip
                                                id={ActionToolTipId + idx + 'Shuffle'}
                                            >
                                                Change Task Status
                                            </Tooltip>
                                        }
                                    >
                                        <Shuffle
                                            style={{ ...actionButtonStyle, color: '#388E3C' }}
                                            onClick={(e) => {
                                                setActionDataFromTask(task);
                                                setChangeTaskStatusEl(e.currentTarget);
                                            }}
                                        />
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                            <Tooltip
                                                id={ActionToolTipId + idx + 'SlideArrowRight'}
                                            >
                                                View Job Candidates
                                            </Tooltip>
                                        }
                                    >
                                        <SlideArrowRight
                                            style={{ ...actionButtonStyle, color: '#305cd5' }}
                                            onClick={(e) => showJobCandidate(task.taskId)} // openJobPage(task.jobId)
                                        />
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                            <Tooltip
                                                id={ActionToolTipId + idx + 'Delete'}
                                            >
                                                Delete Task
                                            </Tooltip>
                                        }
                                    >
                                        <Delete
                                            style={{ ...actionButtonStyle, color: '#DC143C' }}
                                            onClick={(e) => deleteTask(e.currentTarget, task.taskId)}
                                        />
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                            <Tooltip
                                                id={ActionToolTipId + idx + 'PersonSwap'}
                                            >
                                                Change Assignee
                                            </Tooltip>
                                        }
                                    >

                                        <PersonSwap
                                            style={{ ...actionButtonStyle, color: '#FFA500' }}
                                            onClick={(e) => {
                                                setActionDataFromTask(task);
                                                setTaskAssigneeChangeEl(e.currentTarget);
                                            }}
                                        />
                                    </OverlayTrigger>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {props.totalTasks === 0 && <p className="text-center my-4">No Task</p>}
            </TableContainer>
            {props.totalTasks > 0 && <TablePagination
                sx={{
                    '& p': {
                        marginBottom: 0
                    }
                }}
                rowsPerPageOptions={[5, 10, 25]}
                colSpan={3}
                count={props.totalTasks}
                rowsPerPage={props.rowsPerPage}
                page={props.currPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                className="px-md-4"
                component={"div"}
            />}

            {/* Change Task Assignee */}
            <Menu
                anchorEl={taskAssigneeChangeEl}
                open={Boolean(taskAssigneeChangeEl)}
                onClose={() => {
                    resetActionData();
                    setTaskAssigneeChangeEl(null);
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <MenuItem disabled={true} >Change Assignee</MenuItem>
                <div className="p-2" style={{ width: '300px' }}>
                    <Autocomplete
                        options={props.teamMembers}
                        getOptionLabel={(teamMember) => `${teamMember.fullname}`}
                        getOptionDisabled={(teamMember) => teamMember.employeeId === actionTask.taskedAssignedExpert!}
                        onChange={(e, value) => {
                            handleTaskAssigneeChange(actionTask.taskId!,
                                actionTask.jobId!,
                                value?.employeeId!
                            );
                            resetActionData();
                            setTaskAssigneeChangeEl(null);
                        }}
                        renderInput={(params) => <TextField {...params} placeholder="Select Assignee" size="small" />}
                        ListboxProps={
                            {
                                style: {
                                    maxHeight: '200px'
                                }
                            }
                        }
                    />
                </div>
            </Menu>

            {/* Change Task Status Menu*/}
            <Menu
                anchorEl={changeTaskStatusEl}
                open={Boolean(changeTaskStatusEl)}
                onClose={() => {
                    resetActionData();
                    setChangeTaskStatusEl(null);
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                onClick={(e) => {
                    resetActionData();
                    setChangeTaskStatusEl(null);
                }}
            >
                <MenuItem disabled={true} >Select new status</MenuItem>
                {actionTask.status && TaskStatusToKeyArray.map((status, idx) => {
                    if (actionTask.status === status.value) {
                        return null;
                    }
                    return (
                        <MenuItem
                            onClick={() => handleTaskStatusChange(
                                actionTask.taskId!,
                                actionTask.jobId!,
                                status.value
                            )}
                            key={idx + status.value + 'change_status'}
                        >
                            <TaskStatusToIcon status={status.value} label={StatusToDisplayText[status.value]} />
                        </MenuItem>
                    )
                })}
            </Menu>

            {/* Action Confirmation menu */}
            <Menu
                anchorEl={confirmBoxEl}
                open={Boolean(confirmBoxEl)}
                onClose={() => setConfirmBoxEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                onClick={(e) => {
                    setConfirmBoxEl(null);
                }}
            >
                <div className="px-3 text-center">
                    <span className="d-block">Are you sure you want to proceed with this action?</span>
                    <Button variant="text" onClick={() => onConfirmAction()}>Yes</Button>
                    <Button variant="text" onClick={() => onCancelAction()}>No</Button>
                </div>
            </Menu>
        </>
    )
}

type TablePaginationActionsProps = {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
        event: React.MouseEvent<HTMLButtonElement>,
        newPage: number,
    ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <SkipForwardFill width={'1.5rem'} /> : <SkipBackwardFill width={'1.5rem'} />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <CaretForward width={'1.5rem'} /> : <CaretBack width={'1.5rem'} />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <CaretBack width={'1.5rem'} /> : <CaretForward width={'1.5rem'} />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <SkipBackwardFill width={'1.5rem'} /> : <SkipForwardFill width={'1.5rem'} />}
            </IconButton>
        </Box>
    );
}

export default TaskTable;