import { Slider, Tab, Tabs, Tooltip } from "@mui/material";
import { LinkedinSquare } from '@styled-icons/boxicons-logos/LinkedinSquare';
import { CheckShield } from '@styled-icons/boxicons-regular/CheckShield';
import UserImageNotAvilable from 'assets/userImageNotAvilable.png';
import { EllipsedSpan } from "components/CommonStyles";
import { isNumber, startCase, toLower, toNumber } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { CandidateType } from "types/Jobs";
import { scrollToProvidedRef } from "utilities";
import { redirectToNewTab } from "utilities/commonUtils";
import { VerticalLine } from "../Jobs/CreateJob/styled";

type IProps = {
    selectedCandidate: CandidateType;
}

const Wrapper = styled.div`
    max-width: 90rem;
    margin: auto;
    .detailed-info-container {
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        margin-top: 1rem;
        border-radius: 0.5rem;
    }
    .candidate-image {
        width: 8rem;
        height: 8rem;
    }
    .w-30 {
        width: 30%;
    }
    .project-details {
        box-shadow: 0 0 0 1px #faf8ff;
        border-radius: 10px;
        background: #faf8ff;
    }
    .slider-element {
        max-width: 20rem;
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }
    .evolution-score-table td {
        padding: 0.5rem 1rem;
    }
    .rating-container {
        border: 1px solid #e6e6e6;
        border-radius: 0.5rem;
        padding: 1rem;
        margin-bottom: 1rem;
    }
    .rating-container .rating-title {
        display: inline-block;
        padding: 0.5rem;
        border-radius: 0.5rem;
        color: #ffffff;
        margin-bottom: 0.5rem;
    }
    .option-tabs {
        position: sticky;
        top: 6.4%;
        background: #f4f3fa;
        border-radius: 0.5rem;
        z-index: 1;
    }
    td {
        padding: 0.2rem 1rem;
    }
    a {
        max-width: 20rem;
        display: inline-block;
    }
`;

type TabValuesType = 'candidateInformation' | 'education' | 'experience' | 'expertise' | 'ratingAndReview';
const TabsOptions: { value: TabValuesType, label: string }[] = [
    { value: 'candidateInformation', label: 'Candidate Information' },
    { value: 'education', label: 'Education' },
    { value: 'experience', label: 'Experience' },
    { value: 'expertise', label: 'Expertise' },
    { value: 'ratingAndReview', label: 'Rating And Review' },
]

const ColorMapRating: { [key: number]: string } = {
    0: '#04d125',
    1: '#f39202',
    2: '#ff5733',
    3: '#33c1ff',
}
const totalColors = Object.keys(ColorMapRating).length;

const DetailsCandidateInfo = (props: IProps) => {

    const candidate = props.selectedCandidate;
    const profileRef = useRef<any>(null),
        educationRef = useRef<any>(null),
        experienceRef = useRef<any>(null),
        expertiseRef = useRef<any>(null),
        ratingAndReviewRef = useRef<any>(null);
    const targetElementRef = useRef<any>(null);
    const heightAdjustingDiv = useRef<any>(null);

    const [selectedTab, setSelectedTab] = useState<TabValuesType>('candidateInformation');

    const handleChange = (event: React.SyntheticEvent, newValue: TabValuesType) => {
        setSelectedTab(newValue);

        let scrollElementRef: React.MutableRefObject<any>;
        if (newValue === 'candidateInformation') {
            scrollElementRef = profileRef;
        } else if (newValue === 'education') {
            scrollElementRef = educationRef;
        } else if (newValue === 'experience') {
            scrollElementRef = experienceRef;
        } else if (newValue === 'expertise') {
            scrollElementRef = expertiseRef;
        } else {
            scrollElementRef = ratingAndReviewRef;
        }
        scrollToPosition(scrollElementRef!);
    };

    const scrollToPosition = (scrollElementRef: React.MutableRefObject<any>) => {
        if (scrollElementRef.current && targetElementRef.current) {
            scrollToProvidedRef(scrollElementRef, 110);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        setSelectedTab('candidateInformation');
    }, [props.selectedCandidate])

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px 0px -85% 0px', // This will trigger when the element is 85% out of the viewport from the bottom
            threshold: 0
        };

        const callback = (entries: IntersectionObserverEntry[]) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const tabValue = entry.target.getAttribute('data-tab-value');
                    if (tabValue) {
                        setSelectedTab(tabValue as TabValuesType);
                    }
                }
            });
        };

        const observer = new IntersectionObserver(callback, options);
        const detailedInfoContainers = document.querySelectorAll('.tab-change-trigger-div');

        detailedInfoContainers.forEach(container => {
            observer.observe(container);
        });

        return () => {
            detailedInfoContainers.forEach(container => {
                observer.unobserve(container);
            });
        };
    }, []);

    useEffect(() => {
        if (ratingAndReviewRef.current && heightAdjustingDiv.current) {
            const ratingAndReviewHeight = ratingAndReviewRef.current.clientHeight;
            heightAdjustingDiv.current.style.height = `calc(100vh - ${ratingAndReviewHeight + 140}px)`;
        }
    }, [props.selectedCandidate]);

    return (
        <Wrapper>
            <div className="option-tabs" ref={targetElementRef}>
                <Tabs
                    value={selectedTab}
                    onChange={handleChange}
                >
                    {TabsOptions.map((data, idx) => {
                        return <Tab
                            value={data.value}
                            label={data.label}
                            key={data.value + idx}
                            className="py-0"
                        />;
                    })}
                </Tabs>
            </div>
            <div className="tab-change-trigger-div" data-tab-value="candidateInformation"></div>
            <div className="detailed-info-container p-3 px-5" ref={profileRef}>
                <div className="d-flex align-items-center">
                    <img src={candidate.candidateProfileImageUrl ?? UserImageNotAvilable} className="candidate-image rounded-circle mr-3" />
                    <div>
                        <div className="d-flex align-items-center mb-3">
                            <h3 className="m-0 mr-2">{candidate.name}</h3>
                            {candidate.linkedInUrl && <LinkedinSquare
                                size={25}
                                className="mr-2"
                                color="#2866b1"
                                onClick={(e) => redirectToNewTab(candidate.linkedInUrl)}
                            />}
                            {candidate.alreadyAddedToJobs && <Tooltip
                                title={'The candidate has already been added as a "UI/UX Designer" to the talent pool.'}
                                arrow
                                placement="top"
                            >
                                <CheckShield size={25} className="mr-2" />
                            </Tooltip>}
                        </div>
                        <div>
                            <p className="d-flex align-items-center">
                                {candidate.currentDesignation ? candidate.currentDesignation : ''} {candidate.currentDesignation && isNumber(candidate.experienceYears) ? <VerticalLine /> : ''}
                                {isNumber(candidate.experienceYears) ? `${candidate.experienceYears} years of experience ` : ' '} {candidate.state || candidate.country ? <VerticalLine /> : ''}
                                {candidate.state ? `${candidate.state}` : ''} {candidate.state && candidate.country ? ', ' : ''} {candidate.country ? `${candidate.country}` : ''} {candidate.timezone ? <VerticalLine /> : ''}
                                {candidate.timezone ? candidate.timezone : ''}
                            </p>
                        </div>
                    </div>
                </div>
                <hr />
                <table className="table table-borderless">
                    <tbody>
                        {candidate.email && <tr>
                            <td className="font-weight-bold w-30">Email:</td>
                            <td>{candidate.email}</td>
                        </tr>}
                        {candidate?.company?.name && candidate?.company?.type && <tr>
                            <td className="font-weight-bold w-30">Company and Type:</td>
                            <td className="d-flex">{candidate?.company?.name} <VerticalLine /> {startCase(toLower(candidate?.company?.type))}</td>
                        </tr>}
                        <tr>
                            <td className="font-weight-bold w-30">University:</td>
                            <td>Need to add</td>
                        </tr>
                        {candidate.jobType && <tr>
                            <td className="font-weight-bold w-30">Job type:</td>
                            <td>{startCase(toLower(candidate.jobType))}</td>
                        </tr>}
                        {candidate.languages && candidate.languages.length > 0 && <tr>
                            <td className="font-weight-bold w-30">Language name:</td>
                            <td>{candidate.languages.join(', ')}</td>
                        </tr>}
                        {candidate.candidateTypes && <tr>
                            <td className="font-weight-bold w-30">Candidate type:</td>
                            <td>{startCase(toLower(candidate.candidateTypes))}</td>
                        </tr>}
                        {isNumber(candidate.expectedSalary) && <tr>
                            <td className="font-weight-bold w-30">Expected Salary (Yearly):</td>
                            <td>${candidate.expectedSalary}</td>
                        </tr>}
                        {candidate.resumeUrl && <tr>
                            <td className="font-weight-bold w-30">Resume:</td>
                            <td>
                                <a className="btn-link" onClick={(e) => redirectToNewTab(candidate.resumeUrl)}><EllipsedSpan>{'Download'}</EllipsedSpan></a>
                            </td>
                        </tr>}
                        {candidate.videoUrl && <tr>
                            <td className="font-weight-bold w-30">Video intro:</td>
                            <td>
                                <a className="btn-link" onClick={() => redirectToNewTab(candidate.videoUrl!)}> <EllipsedSpan>{'View'}</EllipsedSpan></a>
                            </td>
                        </tr>}
                    </tbody>
                </table>
            </div>
            <div className="tab-change-trigger-div" data-tab-value="education"></div>
            <div className="detailed-info-container p-3 px-5" ref={educationRef}>
                <h5 className="text-muted mb-3">EDUCATION</h5>
                {candidate.educationDetails && candidate.educationDetails?.length > 0 &&
                    candidate.educationDetails.map((data, idx: number) => {
                        return (
                            <>
                                <table className="table table-borderless" key={data.university + idx}>
                                    <tbody>
                                        {data.degree && <tr>
                                            <td className="font-weight-bold w-30 pl-0">Degree:</td>
                                            <td>{data.degree}</td>
                                        </tr>}
                                        {data.passingYear && <tr>
                                            <td className="font-weight-bold w-30 pl-0">Passing year:</td>
                                            <td>{data.passingYear}</td>
                                        </tr>}
                                        {data.university && <tr>
                                            <td className="font-weight-bold w-30 pl-0">University:</td>
                                            <td>{data.university}</td>
                                        </tr>}
                                        {isNumber(data.universityRank) && <tr>
                                            <td className="font-weight-bold w-30 pl-0">University rank:</td>
                                            <td>{data.universityRank}</td>
                                        </tr>}
                                    </tbody>
                                </table>
                                {candidate.educationDetails && candidate.educationDetails.length !== idx + 1 && <hr key={idx + 'edu'} />}
                            </>
                        )
                    })}
            </div>
            <div className="tab-change-trigger-div" data-tab-value="experience"></div>
            <div className="detailed-info-container p-3 px-5" ref={experienceRef}>
                <h5 className="text-muted mb-3">EXPERIENCE</h5>
                {candidate.candidateExperience && candidate.candidateExperience?.length > 0 &&
                    candidate.candidateExperience.map((exp, idx: number) => {
                        return (
                            <>
                                <table className="table table-borderless mb-2" key={exp.company + idx + 't-1'}>
                                    <tbody>
                                        {exp.company && <tr>
                                            <td className="font-weight-bold w-30 pl-0">Company:</td>
                                            <td>{exp.company}</td>
                                        </tr>}
                                        {exp.duration && <tr>
                                            <td className="font-weight-bold w-30 pl-0">Duration:</td>
                                            <td>{exp.duration}</td>
                                        </tr>}
                                        {exp.title && <tr>
                                            <td className="font-weight-bold w-30 pl-0">Job title:</td>
                                            <td>{exp.title}</td>
                                        </tr>}
                                    </tbody>
                                </table>
                                {exp.projects?.length > 0 &&
                                    exp.projects.map((proj, idx: number) => {
                                        return (
                                            <div className="project-details mb-2 py-2" key={proj.projectName + idx + 't-2'}>
                                                <table className="table table-borderless">
                                                    <tbody>
                                                        {proj.projectName && <tr>
                                                            <td className="font-weight-bold w-30">Project 1:</td>
                                                            <td>{proj.projectName}</td>
                                                        </tr>}
                                                        {proj.impactWithMetrics && <tr>
                                                            <td className="font-weight-bold w-30">Key impact with matrices:</td>
                                                            <td>{proj.impactWithMetrics}</td>
                                                        </tr>}
                                                    </tbody>
                                                </table>
                                            </div>
                                        )
                                    })}
                                {candidate.candidateExperience && candidate.candidateExperience.length !== idx + 1 && <hr key={idx + 'exp'} />}
                            </>
                        )
                    })
                }
            </div>
            <div className="tab-change-trigger-div" data-tab-value="expertise"></div>
            <div className="detailed-info-container p-3 px-5" ref={expertiseRef}>
                <h5 className="text-muted mb-3">EXPERTISE</h5>
                <h5 className="mb-3">Skill</h5>
                {candidate.skills && candidate.skills?.length > 0 &&
                    candidate.skills.map((skill, idx: number) => {
                        return (
                            <div key={skill.skillName + idx}>
                                <span>{startCase(skill.skillName)}</span>
                                <div className={`slider-element ${toNumber(skill.score) === 1 ? 'pl-2' : ''}`}>
                                    <Slider
                                        value={skill.score}
                                        min={1}
                                        max={10}
                                        disabled
                                        className="mr-2"
                                    />
                                    <span>{skill.score + '/' + 10}</span>
                                </div>
                            </div>
                        )
                    })}
                {candidate.evaluationScores && candidate.evaluationScores?.length > 0 &&
                    <>
                        <hr />
                        <h5 className="mb-3">Evolution Score</h5>
                        <table className="table evolution-score-table">
                            <thead>
                                <th>Track name</th>
                                <th>Score</th>
                            </thead>
                            <tbody>
                                {candidate.evaluationScores.map((evolution, idx: number) => {
                                    return (
                                        <tr key={evolution.trackName + idx}>
                                            <td>{evolution.trackName}</td>
                                            <td>{evolution.score}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </>
                }
            </div>
            <div className="tab-change-trigger-div" data-tab-value="ratingAndReview"></div>
            <div className="detailed-info-container p-3 px-5 mb-4" ref={ratingAndReviewRef}>
                <h5 className="text-muted mb-3">RATING AND REVIEW</h5>
                <h5 className="mb-3">Career Progress</h5>
                {candidate.careerProgress && candidate.careerProgress?.length > 0 &&
                    candidate.careerProgress.map((progression, idx: number) => {
                        return (
                            <div className="rating-container">
                                <div
                                    className="rating-title font-weight-bold"
                                    style={{ background: ColorMapRating[idx % totalColors] }}
                                >
                                    {progression.progress}
                                </div>
                                <p className="mb-0">{progression.progressDescription}</p>
                            </div>
                        )
                    })
                }
            </div>
            <div ref={heightAdjustingDiv}></div>
        </Wrapper>
    )
}

export default DetailsCandidateInfo;