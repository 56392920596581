
import React, { useRef, useState, useEffect } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

type IProps = {
    title: string;
    className?: string;
    children: React.ReactNode;
}

const OverflowTip = ({ title, className, children }: IProps) => {
    const [isOverflowed, setIsOverflow] = useState(false);
    const textElementRef = useRef<any | null>(null);
    useEffect(() => {
        console.log(textElementRef.current.scrollWidth, textElementRef.current.clientWidth)
        setIsOverflow(textElementRef.current.scrollWidth > textElementRef.current.clientWidth);
    }, []);
    return (
        <OverlayTrigger
            placement="bottom"
            overlay={
                isOverflowed ?
                    <Tooltip
                        id={'overflow-tooltip' + title}
                    >
                        {title}
                    </Tooltip> : <span />
            }
        >
            <div
                ref={textElementRef}
                style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
                className={className}
            >
                {children}
            </div>
        </OverlayTrigger>
    );
};

export default OverflowTip;