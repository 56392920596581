import { ChevronDoubleRight } from '@styled-icons/bootstrap/ChevronDoubleRight';
import { LinkAlt } from '@styled-icons/boxicons-regular/LinkAlt';
import { Edit as EditIcon } from '@styled-icons/boxicons-solid/Edit';
import { Globe } from "@styled-icons/entypo/Globe";
import { Lock } from "@styled-icons/entypo/Lock";
import { removeJobFromEditedJobIds } from "actions/hiringManager/jobs/jobsSlice";
import { LighSpan, NormalSpan } from 'components/CommonStyles';
import { JOB_ACCESS_CONSTANT, getLocationInOptionDataFormat, jobTypes } from "containers/HiringManager/Jobs.util";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import ReactTooltip from 'react-tooltip';
import { jobsService } from "services/jobs";
import { RootState } from "store";
import { AttributeEntity, Candidates_Count, LocationType, OptionsDataType } from "types/Jobs";
import { theme } from "../constants";
import { CapabilitiesModal, CapabilityLink } from './CapabilitiesModal';
import { EditJob as EditJobModal } from './CreateJob';
import { CardBody, CardFooter, EditJob, JobAccessInfo, JobCardCandidatesWrapper, SubmitButton } from "./Jobs.styled";
import { CardWrapper } from "./JobsContainer";
import { useHistory, useLocation } from 'react-router';

type Props = {
  jobId: string;
  title: string;
  jobTitle: string;
  trackId: string;
  jobType: string;
  jobLink: string;
  active: boolean;
  logoUrl: string;
  openPositions: string;
  jobAccess: string;
  closePositions?: string;
  equity?: string;
  attributes: AttributeEntity[];
  candidatesCount: Candidates_Count;
  myCandidatesCount?: Candidates_Count;
  handleCheckCandidates: (jobId: string) => void;
  employmentType?: string;
  minSalary?: number;
  maxSalary?: number;
  currency?: string;
  locations?: LocationType[];
  expertInfo?: {
    fullname: string
  }
  companyInfo?: {
    companyId: string,
    companyName: string,
    companyLogo?: string
  }
  publicJobsFilter?: boolean;
};

const CandidateCountDisplayer = (props: { label: number | string, loading: boolean }) => {
  const iconCss = {
    width: "20px",
    height: "20px",
    color: "#3694D7",
  }
  if (props.loading) {
    return <ClipLoader cssOverride={iconCss} color='#3694D7' loading={true} speedMultiplier={3} className='my-1' />
  }
  return <>{props.label}</>;
}

export const JobsCard = ({ jobId,
  title,
  jobTitle,
  trackId,
  jobType,
  jobLink,
  openPositions,
  jobAccess,
  closePositions,
  attributes,
  active,
  logoUrl,
  candidatesCount,
  myCandidatesCount,
  handleCheckCandidates,
  equity,
  employmentType,
  minSalary,
  maxSalary,
  currency,
  locations,
  publicJobsFilter,
  companyInfo,
  expertInfo }: Props) => {
  const [showCapabilites, setShowCapabilites] = useState<boolean>(false);
  const [showEditJob, setShowEditJob] = useState(false);
  const [loadingCounts, setLoadingCounts] = useState(false);
  const [newCandidatesCount, setNewCandidatesCount] = useState<Candidates_Count>();
  const newCandidatesCountRef = useRef<any>();
  const [preSelectedLocation, setPreSelectedLocation] = useState<OptionsDataType[]>([]);
  const history = useHistory();
  const location = useLocation();

  const fetchJobs = useSelector((state: RootState) => state.jobs.fetchJobs);
  const letestEditedJobIds = useSelector((state: RootState) => state.jobs.letestEditedJobIds);
  const expertId = useSelector((state: RootState) => state.auth.user.expertId);
  const dispatch = useDispatch();

  const handleShowCapabilities = () => setShowCapabilites(true);

  const handleHideCapabilities = () => setShowCapabilites(false);

  const openJobLink = () => {
    window.open(jobLink);
  };

  useEffect(() => {
    setNewCandidatesCount(candidatesCount);
    newCandidatesCountRef.current = candidatesCount;
  }, [candidatesCount]);

  useEffect(() => {
    let candidateCountInterval: NodeJS.Timeout | undefined;
    if (letestEditedJobIds[jobId]) {
      setLoadingCounts(true);
      candidateCountInterval = setInterval(() => {
        jobsService.getJobCandidatesCount(jobId, expertId).then((res) => {
          const counts: Candidates_Count = res.output.candidatesCount;
          let countsAreSame = true;
          for (const key in counts) {
            if (newCandidatesCountRef.current[key as keyof Candidates_Count] !== counts[key as keyof Candidates_Count]) {
              countsAreSame = false;
              break;
            }
          }
          if (countsAreSame) {
            dispatch(removeJobFromEditedJobIds(jobId));
            setLoadingCounts(false);
          } else {
            setNewCandidatesCount((prev) => {
              newCandidatesCountRef.current = { ...prev, ...counts };
              return { ...prev, ...counts };
            });
          }
        })
      }, 5000);
    }

    return () => {
      clearInterval(candidateCountInterval);
    }
  }, [letestEditedJobIds[jobId]])

  useEffect(() => {
    if (fetchJobs && showEditJob) {
      setShowEditJob(false);
    }
  }, [fetchJobs, showEditJob]);

  useEffect(() => {
    setPreSelectedLocation(getLocationInOptionDataFormat(locations));
  }, [locations])

  const moveToJobDetails = (jobId: string) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('jobId', jobId);
  
    const newUrl = `${location.pathname}?${searchParams.toString()}`;
    history.push(newUrl);
  }

  return (
    <>
      <CardWrapper className="job-card">
        <CardBody active={active}>
          <JobAccessInfo>
            {jobAccess === JOB_ACCESS_CONSTANT.PUBLIC && <>
              <Globe
                color="forestgreen"
                data-tip="Public Job"
                data-for="job-access-info-tooltip-public"
              />
              <ReactTooltip id="job-access-info-tooltip-public" type="dark" />
            </>}
            {jobAccess === JOB_ACCESS_CONSTANT.PRIVATE && <>
              <Lock
                color="gold"
                data-tip="Private Job"
                data-for="job-access-info-tooltip-private"
              />
              <ReactTooltip id="job-access-info-tooltip-private" type="dark" />
            </>}
          </JobAccessInfo>
          <EditJob onClick={() => setShowEditJob(true)} disabled={loadingCounts || !!publicJobsFilter}>
            <EditIcon />
          </EditJob>
          <div className="card-content">
            <Row style={{ fontSize: '19px' }} className="mb--20 font-weight-bold my-3">
              {jobTitle}
            </Row>
            {expertInfo && <Row className="title">
              <Col md={3} className='p-0'>
                <span className='font-weight-bold heading'>Owner:</span>
              </Col>
              <Col className='p-0'>
                <span className='font-weight-bold'>{expertInfo.fullname}</span>
              </Col>
            </Row>}
            {companyInfo && <Row className="title">
              <Col md={3} className='p-0'>
                <span className="font-weight-bold heading">Company:</span>
              </Col>
              <Col className='p-0'>
                <div className='d-flex align-items-center'>
                  {companyInfo.companyLogo && <img src={companyInfo.companyLogo} alt="" className="rounded img-fluid mr-2" width="20" />}
                  <span className="value">{companyInfo.companyName}</span>
                </div>
              </Col>
            </Row>}
            <Row className="title">
              <Col md={3} className='p-0'>
                <span className="font-weight-bold heading">Track:</span>
              </Col>
              <Col className='p-0'>
                <span className="value">{title}</span>
              </Col>
            </Row>
            <Row className="title">
              <Col md={3} className='p-0'>
                <span className="font-weight-bold heading">Total Open Positions:</span>
              </Col>
              <Col className='p-0'>
                <span className="value">{openPositions} openings</span>
              </Col>
            </Row>
            <Row className="title">
              <Col md={3} className='p-0'>
                <span className="font-weight-bold heading">Job Type:</span>
              </Col>
              <Col className='p-0'>
                <span className="value">{jobTypes.find(o => o.key === jobType)?.value}</span>
              </Col>
            </Row>
            <Row>
              <CapabilityLink fz={16} svgh={18}>
                <div onClick={handleShowCapabilities}>
                  View capabilities
                  <ChevronDoubleRight />
                </div>
                <CapabilitiesModal
                  showScore
                  attributes={attributes}
                  show={showCapabilites}
                  handleClose={handleHideCapabilities}
                  isJobsCard
                  dialogClassName={`capabilities-dialog candidates-capabilities-dialog-${isMobile && 'mobile'}`}
                />
              </CapabilityLink>
            </Row>
            {jobLink && (
              <Row className="d-flex align-items-center" style={{ fontSize: '13px' }}>
                <CapabilityLink fz={16} mt={4} svgh={18}>
                  <div onClick={openJobLink}>
                    View job description
                    <LinkAlt />
                  </div>
                </CapabilityLink>
              </Row>
            )}
            {closePositions && (
              <Row className="title">
                <span className="font-weight-bold">Total Matched Positions:</span>
                <span className="value">{closePositions} positions</span>
              </Row>
            )}
          </div>
        </CardBody>
        <CardFooter active={active} style={{ width: '100%' }}>
          <Row style={{ width: '100%', justifyContent: 'center' }}>
            <SubmitButton onClick={() => moveToJobDetails(jobId)} disabled={loadingCounts}>
              Check Candidates
            </SubmitButton>
          </Row>
        </CardFooter>
        {newCandidatesCount && (
          <JobCardCandidatesWrapper>
            <div style={{ background: theme.colors.TALENT_POOL }} className="d-flex">
              <div className="candidate-item heading"></div>
              <div className="candidate-item heading">Talent Pool</div>
              {!publicJobsFilter && <div className="candidate-item heading">Pre-vetted</div>}
              <div className="candidate-item heading">Engaged</div>
              <div className="candidate-item heading">Interview</div>
              <div className="candidate-item heading">Finalised</div>
            </div>
            {myCandidatesCount && <div style={{ background: theme.colors.INTERVIEW }} className="d-flex">
              <div className="candidate-item text-center">
                <NormalSpan>Your</NormalSpan>
              </div>
              <div className="candidate-item">
                <CandidateCountDisplayer label={myCandidatesCount?.talentPool ?? ''} loading={loadingCounts} />
              </div>
              {!publicJobsFilter && <div className={`candidate-item ${myCandidatesCount?.preVetted ? 'new-label' : ''}`}>
                <CandidateCountDisplayer label={myCandidatesCount?.preVetted} loading={loadingCounts} />
              </div>}
              <div className={`candidate-item ${myCandidatesCount?.newVettedCandidates ? 'new-label' : ''}`}>
                <CandidateCountDisplayer label={myCandidatesCount?.vetted ?? ''} loading={loadingCounts} />
              </div>
              <div className="candidate-item">
                <CandidateCountDisplayer label={myCandidatesCount?.interviewRequest ?? ''} loading={loadingCounts} />
              </div>
              <div className="candidate-item">
                <CandidateCountDisplayer label={myCandidatesCount?.finalized ?? ''} loading={loadingCounts} />
              </div>
            </div>}
            <div style={{ background: theme.colors.INTERVIEW }} className="d-flex">
              <div className="candidate-item text-center bl-border">
                <NormalSpan>{'All'}</NormalSpan>
              </div>
              <div className="candidate-item">
                <CandidateCountDisplayer label={newCandidatesCount.talentPool} loading={loadingCounts} />
              </div>
              {!publicJobsFilter && <div className="candidate-item">
                <CandidateCountDisplayer label={newCandidatesCount.preVetted} loading={loadingCounts} />
              </div>}
              <div className={`candidate-item ${newCandidatesCount.newVettedCandidates ? 'new-label' : ''}`}>
                <CandidateCountDisplayer label={newCandidatesCount.vetted} loading={loadingCounts} />
              </div>
              <div className="candidate-item">
                <CandidateCountDisplayer label={newCandidatesCount.interviewRequest} loading={loadingCounts} />
              </div>
              <div className="candidate-item br-border">
                <CandidateCountDisplayer label={newCandidatesCount.finalized} loading={loadingCounts} />
              </div>
            </div>
          </JobCardCandidatesWrapper>
        )}
        {showEditJob && (
          <EditJobModal
            jobId={jobId}
            jobTrack={title}
            jobTitle={jobTitle}
            jobTrackId={trackId}
            openPositions={Number(openPositions)}
            jobType={jobType}
            jobLink={jobLink}
            activeJob={active}
            attributes={attributes}
            logoUrl={logoUrl}
            equity={equity!}
            onClose={() => setShowEditJob(false)}
            employmentType={employmentType}
            minSalary={minSalary}
            maxSalary={maxSalary}
            currency={currency}
            selectedLocations={preSelectedLocation}
            jobAccess={jobAccess}
          />
        )}
      </CardWrapper>
    </>
  )
};